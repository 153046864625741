import 'core-js/es/promise';
import 'core-js/es/promise/finally';
import 'core-js/es/string/pad-start';
import 'core-js/es/object/assign';
import 'core-js/es/object/entries';
import 'core-js/es/object/values';
import 'core-js/es/number/is-integer';
import 'core-js/es/number/is-finite';
import 'core-js/es/array/concat';
import 'core-js/es/array/find';
import 'core-js/es/array/find-index';
import 'core-js/es/array/from';
import 'core-js/es/array/includes';
import 'core-js/es/symbol';
import 'core-js/es/math/sign';
import 'core-js/es/array/at';
import 'core-js/proposals/set-methods-v2';

if (!Element.prototype.remove) {
    Object.defineProperty(Element.prototype, 'remove', {
        configurable: true,
        enumerable: true,
        writable: true,
        value: function remove() {
            this.parentNode.removeChild(this);
        }
    });
}

// Source: https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector ||
                                Element.prototype.webkitMatchesSelector;
}

// Source: https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
if (!Element.prototype.closest) {
    Element.prototype.closest = function(s) {
        let el = this;

        do {
            if (el.matches(s)) {
                return el;
            }
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}

// Missing support: IE11
for (const elType of [
    HTMLFormElement,
    HTMLInputElement,
    HTMLButtonElement,
    HTMLObjectElement,
    HTMLSelectElement,
    HTMLTextAreaElement,
]) {
    if (!elType.prototype.reportValidity) {
        // Fall back to checkValidity
        elType.prototype.reportValidity = function () {
            return this.checkValidity();
        };
    }
}

// https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent#Polyfill
if (typeof window.CustomEvent === "undefined") {
    window.CustomEvent = function CustomEvent(event, params = {}) {
        params = { ...params, bubbles: false, cancelable: false, detail: null };
        const evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
    };
}


/**
 * Safari does not support our MySQL datetime format. Trying to create a Date
 * object with that format will give a date object that toString()'s to "Invalid
 * Date" which can go unnoticed. Proxy the built-in Date constructor to log a
 * Sentry error if an invalid date is constructed.
 */
const NativeDate = window.Date;
window.Date = new Proxy(NativeDate, {
    construct(target, args) {
        if (args.length === 1 && /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}(:\d{2})?$/.test(args[0])) {
            const msg = `Date object created with MySQL datetime format: ${args[0]}\n\nMySQL datetime format is not supported by all browsers.`;
            const err = new Error(msg);
            err.constructor_args = { args };
            console.error(err);
            window.Sentry.captureException(err);
        }
        const dateObj = new target(...args);
        if (dateObj.toString() === 'Invalid Date') {
            const msg = `Invalid Date object created: ${JSON.stringify(args)}\n\nNote that MySQL datetime format is not supported by all browsers.`;
            const err = new Error(msg);
            err.constructor_args = { args };
            console.error(err);
            window.Sentry.captureException(err);
        }
        return dateObj;
    }
});
